import { DateHelper } from "@/helpers/date";
import { emailUnique } from "@/helpers/registration";
import { and, email, helpers, minLength, numeric, required, requiredIf } from "@vuelidate/validators";
import { Ref, computed, ref, reactive } from "vue";

export function useIndividualValidation(isAuthenticated: Ref<boolean>) {

    const showExtra = ref(false);

    const ageGate = (_: any, _siblings: any, component: any) => {
        return !component.v$.birthdate.$error;
    };
    const checkDuplicate = helpers.withAsync(
        emailUnique(isAuthenticated)
    );

    const calendarHelper = new DateHelper();
    const rules = computed(() => {
        return {
            password: {
                required: requiredIf(
                  computed(() => {
                    return !isAuthenticated.value;
                  }).value
                ),
            },
            extra: { required: requiredIf(showExtra.value) },
            selectedMotivations: {
                required: and(required, minLength(1)),
            },
            selectedTasks: {
                required: and(required, minLength(1)),
            },
            selectedPostcodePrefix: { required },
            birthdate: {
                required,
                age: calendarHelper.checkAge,
            },
            firstName: {
                required,
            },
            country: {
                required,
            },
            language: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
                email,
                unique: helpers.withAsync(checkDuplicate),
            },
            phoneNumber: {
                required,
                numeric,
            },
            terms: {
                required,
            },
            privacy: {
                required,
            },
            postcode: { required },
            day: { rules: and(required, ageGate) },
            month: { rules: and(required, ageGate) },
            year: { rules: and(required, ageGate) },
        }
    });

    const state = reactive({
        birthdate: "",
        selectedPostcodePrefix: "be",
        extra: "",
        postcode: "",
        firstName: "",
        lastName: "",
        selectedMotivations: [] as string[],
        selectedTasks: [] as string[],
        password: "",
        email: "",
        selectedPrefix: "+32",
        phoneNumber: "",
        day: 0,
        month: 0,
        year: 0,
        country: "",
        language: "",
        privacy: false,
        terms: false,
        remarks: ""
      });
    
    return {
        showExtra,
        rules,
        state,
    };
}