
import { defineComponent, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  and,
  email,
  minLength,
  numeric,
  required,
  helpers,
} from "@vuelidate/validators";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import AHgroup from "@/components/atoms/a-hgroup.vue";
import AInput from "@/components/atoms/a-input.vue";
import APassword from "@/components/atoms/a-password.vue";
import { useMsal } from "@/composition-api/useMsal";
import { DateHelper } from "@/helpers/date";
import { loginRequestWithLocale } from "@/plugins/msal/config";
import { SUPPORTED_LOCALES } from "@/plugins/i18n/config";
import { saveRegistration } from "@/services/user.service";
import { emailUnique, toastSettings } from "@/helpers/registration";
import { REGISTRATION_PATHS, ROUTE_PATHS } from "@/router/config";
import LetsMove from "@/assets/letsmove-logo.svg";
import { getConsents, getFromVueEnv } from "@/helpers/misc";
import { useIndividualValidation } from "@/composition-api/useIndividualValidation";

export default defineComponent({
  components: {
    AInput,
    AHgroup,
    APassword,
  },
  watch: {
    isAuthenticated: {
      handler() {
        this.setFields();
      }
    },
  },
  data() {
    const calendarHelper = new DateHelper();
    const locales = [
      {
        key: "be",
        translation: "general.countries.be",
      },
      {
        key: "nl",
        translation: "general.countries.nl",
      },
    ];
    const days = calendarHelper.getDays();
    const months = calendarHelper.getMonths();
    const years = calendarHelper.getYears();

    const prefixes = [
      { key: "be", code: "+32" },
      { key: "nl", code: "+31" },
    ];
    return {
      calendarHelper,
      telephonePrefixes: prefixes,
      days,
      months,
      years,
      motivations: ["money", "fun", "social", "learn", "extra"],
      tasks: ["steward", "service", "welcome", "green"],
      locales,
      countries: [
        {
          code: this.$t("countries.be"),
          key: "be",
        },
        {
          code: this.$t("countries.nl"),
          key: "nl",
        },
      ],
      languages: SUPPORTED_LOCALES.map((l) => l.toUpperCase()),
      showMessage: false,
      isProcessing: false,
      logo: LetsMove,
    };
  },
  mounted() {
    this.setFields();
  },
  setup() {
    const { isAuthenticated, user } = useIsAuthenticated();
    const { instance } = useMsal();
    const { rules, state, showExtra } = useIndividualValidation(isAuthenticated);
    const v$ = useVuelidate(rules, state, { $lazy: true });

    const setFields = () => {
      if (!isAuthenticated || !user) {
        return;
      }
      state.firstName = user.value?.firstName ?? state.firstName;
      state.lastName = user.value?.lastName ?? state.lastName;
      state.email = user.value?.email ?? state.email;
      state.phoneNumber = user.value?.phone ?? state.phoneNumber;
    };

    return {
      user,
      showExtra,
      setFields, 
      v$,
      instance,
      isAuthenticated,
      state
    };
  },
  methods: {
    login(): void {
      this.instance.loginRedirect(loginRequestWithLocale(this.$i18n.locale));
    },
    navigateToPrivacy() {
      const link = getFromVueEnv(`privacy_${this.$i18n.locale}`);
      if (!link) {
        return;
      }
      window.open(link, "_blank");
    },
    navigateToTerms() {
      const link = getFromVueEnv(`terms_${this.$i18n.locale}`);
      if (!link) {
        return;
      }
      window.open(link, "_blank");
    },
    async handleSubmit() {
      if (!(await this.v$.$validate())) {
        return;
      }

      const consents = getConsents(["terms", "privacy"], this.$i18n.locale);
      const answers = [
        {
          field: "motivations",
          fieldType: "checkbox",
          label: this.$t(`forms.motivations.heading.volunteer`),
          value: this.state.selectedMotivations as string[],
          description: (this.state.selectedMotivations as string[]).map((key) => {
            const translation = this.$t(`forms.motivations.${key}`);
            if (key === "extra") {
              return `${translation}: ${this.v$.extra.$model}`;
            }
            return translation;
          }),
        },
        {
          field: "tasks",
          fieldType: "checkbox",
          label: this.$t(`forms.tasks.heading.individual`),
          value: this.state.selectedTasks as string[],
          description: (this.state.selectedTasks as string[]).map((task) => {
            return `${this.$t(`forms.tasks.${task}.main`)} - ${this.$t(
              `forms.tasks.${task}.sub`
            )}`;
          }),
        },
      ];

      try {
        this.isProcessing = true;
        const birthDate = new Date(this.state.birthdate)
          .toISOString()
          .substr(0, 10);
        const user: Record<string, any> = {
          email: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          phonePrefix: this.state.selectedPrefix,
          phoneNumber: this.state.phoneNumber.replaceAll(/^0+/g, ""),
          birthDate,
          postalCode: this.state.postcode,
        };

        if (!this.isAuthenticated) {
          user.password = this.state.password;
        }

        const response = await saveRegistration(
          {
            language: this.state.language,
            user,
            remarks: this.state.remarks,
            answers,
            consents,
          },
          "individual",
          this.isAuthenticated
        );

        if (!response) {
          throw new Error();
        }

        if (Object.keys(response).length > 0) {
          const key = Object.keys(response).shift();
          const message = key ? response[key] : "";
          throw new Error(message);
        }

        this.$router.push(
          `/${this.$i18n.locale}/${ROUTE_PATHS.REGISTRATION}/${REGISTRATION_PATHS.THANKS}`
        );
      } catch (e) {
        if (e instanceof Error) {
          this.$toast.add(toastSettings(this.$t(e.message)));
        }
        return;
      } finally {
        this.isProcessing = false;
      }
    },
    isNotUnique() {
      return Boolean(
        this.v$.email.$error &&
          this.v$.email.$errors?.[0]?.$validator === "unique"
      );
    },
    setOptions() {
      this.showExtra = (
        this.v$.selectedMotivations.$model as string[]
      ).includes("extra");
    },
    showAgeError() {
      return (
        this.v$.birthdate.$error &&
        this.v$.birthdate.$errors[0].$validator === "age"
      );
    },
    setBirthDate() {
      if (
        !this.state.day ||
        !this.state.month ||
        !this.state.year
      ) {
        return;
      }

      this.v$.birthdate.$model = `${this.state.year}-${this.state.month}-${this.state.day}`;
      this.v$.birthdate.$validate();
    },
  },
});

